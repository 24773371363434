import React from "react";
import styled from 'styled-components';
const Contactmob=function(){
    const HoverDiv = styled.div`
  position: fixed;
  display: flex;
  bottom: 0px;
  z-index: 999;
  
    @media (min-width: 992px) {
    display: none;
  }
`;
    return (<>
    <HoverDiv style={{width:"100%"}}>
        < div style={{ listStyleType:"none",display:"flex",flexWrap:"wrap",width:"100%",marginLeft:"0px",padding:"0px"}}>
            
                <a href="tel:8809076364" style={{width:"100%",padding:"11px 5px",width:"25%",backgroundColor:"#0055ff",margin:"0px"}}>
                    
                <div  style={{display:"flex",justifyContent:"center",marginBottom:"6px"}}>
                        <img style={{width:"24px"}} src="../photo/contact-us.png" alt="" />
                    </div>
                    <div  style={{display:"flex",justifyContent:"center"}}>
                    <h6 style={{marginBottom:"0px",color:"white",fontSize:"12px"}}>Contact Us</h6>
                    </div>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=8809076364&text=Hello" style={{width:"100%",padding:"11px 5px",width:"25%",backgroundColor:"#00B3FE",margin:"0px"}}>
                    
                    <div  style={{display:"flex",justifyContent:"center",marginBottom:"6px"}}>
                            <img style={{width:"24px"}} src="../photo/whatsapp.png" alt="" />
                        </div>
                        <div  style={{display:"flex",justifyContent:"center"}}>
                        <h6 style={{marginBottom:"0px",color:"white",fontSize:"12px"}}>WhatsApp</h6>
                        </div>
                    </a>
                    <a href="https://www.google.com/maps/dir//Dr.Sanjeev+Kumar+-+Pediatrician+,Child+Specialist+%26+Neonatologist+in+Forbesganj+,+Bihar+Ward+No-6+Hospital+Rd+Forbesganj,+Matiari,+Bihar+854318/@26.3072749,87.2575593,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x39ef9f22550b17c7:0x56076192612b3d46" style={{width:"100%",padding:"11px 5px",width:"25%",backgroundColor:"#0055ff",margin:"0px"}}>
                    
                    <div  style={{display:"flex",justifyContent:"center",marginBottom:"6px"}}>
                            <img style={{width:"24px"}} src="../photo/circle.png" alt="" />
                        </div>
                        <div  style={{display:"flex",justifyContent:"center"}}>
                        <h6 style={{marginBottom:"0px",color:"white",fontSize:"12px"}}>Map</h6>
                        </div>
                    </a>
            
                    <a href="https://drsanjeevk.drxpro.in/" style={{width:"100%",padding:"11px 5px",width:"25%",backgroundColor:"#00B3FE",margin:"0px"}}>
                    
                    <div  style={{backgroundColor:"#00B3FE",display:"flex",justifyContent:"center",marginBottom:"6px"}}>
                            <img style={{width:"24px"}} src="../photo/agenda.png" alt="" />
                        </div>
                        <div  style={{display:"flex",justifyContent:"center"}}>
                        <h6 style={{marginBottom:"0px",color:"white",fontSize:"12px"}}>Appoint</h6>
                        </div>
                    </a>
        </ div>
    </HoverDiv>
    </>)
}
export default Contactmob