import React from "react";
import styled from 'styled-components';
const HoverDiv = styled.div`
  position: fixed;
  display: flex;
  top: 40%;
  bottom: -50%;
  z-index: 999;
  transition: left 0.3s ease, background-color 0.3s ease; /* Smooth transition for movement */
  
  &:hover {
    left: 0px; /* Move element into view */
  }
    @media (max-width: 992px) {
    display: none;
  }
`;

const Contactpc=function(){
    return (<>
    <HoverDiv className="contactpc">
        <ul style={{listStyleType:"none",paddingLeft:"0px",marginLeft:"0px"}}>
            <li>
                <a href="tel:8809076364" style={{display:"flex",marginBottom:"8px"}}>
                    <div  style={{backgroundColor:"#00B3FE",display:"flex",alignItems:"center",width:"150px",padding:"0px 10px",marginRight:"20px",borderBottomRightRadius:"10px",borderTopRightRadius:"10px"}}>
                    <h6 style={{marginBottom:"0px",color:"white"}}>Contact Us</h6>
                    </div>
                    <div>
                        <img style={{width:"36px"}} src="../photo/contact-us.png" alt="" />
                    </div>
                </a>
            </li>
            <li>
                <a href="https://api.whatsapp.com/send/?phone=8809076364&text=Hello" style={{display:"flex",marginBottom:"8px"}}>
                    <div  style={{backgroundColor:"#4CAF50",display:"flex",alignItems:"center",width:"150px",padding:"0px 10px",marginRight:"20px",borderBottomRightRadius:"10px",borderTopRightRadius:"10px"}}>
                    <h6 style={{marginBottom:"0px",color:"white"}}>Whatsapp</h6>
                    </div>
                    <div>
                        <img style={{width:"36px"}} src="../photo/whatsapp.png" alt="" />
                    </div>
                </a>
            </li>
            <li>
                <a href="https://www.google.com/maps/dir//Dr.Sanjeev+Kumar+-+Pediatrician+,Child+Specialist+%26+Neonatologist+in+Forbesganj+,+Bihar+Ward+No-6+Hospital+Rd+Forbesganj,+Matiari,+Bihar+854318/@26.3072749,87.2575593,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x39ef9f22550b17c7:0x56076192612b3d46" style={{display:"flex",marginBottom:"8px"}}>
                    <div  style={{backgroundColor:"#FFBB33",display:"flex",alignItems:"center",width:"150px",padding:"0px 10px",marginRight:"20px",borderBottomRightRadius:"10px",borderTopRightRadius:"10px"}}>
                    <h6 style={{marginBottom:"0px",color:"white"}}>Location</h6>
                    </div>
                    <div>
                        <img style={{width:"36px"}} src="../photo/circle.png" alt="" />
                    </div>
                </a>
            </li>
            
            <li>
                <a href="https://drsanjeevk.drxpro.in/" style={{display:"flex"}}>
                    <div  style={{backgroundColor:"#E05B49",display:"flex",alignItems:"center",width:"150px",padding:"0px 10px",marginRight:"20px",borderBottomRightRadius:"10px",borderTopRightRadius:"10px"}}>
                    <h6 style={{marginBottom:"0px",color:"white"}}>Appointment</h6>
                    </div>
                    <div>
                        <img style={{width:"36px"}} src="../photo/agenda.png" alt="" />
                    </div>
                </a>
            </li>
        </ul>
    </HoverDiv>
    </>)
}
export default Contactpc