import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { SERVICES_DETAILS01 } from "../../lib/assets";

export const ServicesDetailsSidebar = ({
  hideContact = false,
  hideTitle = false,
}) => {
  const services = [
    { label: "Child Health", href: "/child-health" },
    { label: "Fitness In Children", href: "/fitness-in-children" },
    { label: "Immunizations", href: "/immunizations" },
    { label: "Injury In Children", href: "/injury-in-children" },
    { label: "Growth Monitoring", href: "/growth-monitoring" },
    {
      label: "Common Illness in Children",
      href: "/common-illness-in-children",
    },
    { label: "Growth & Nutrition", href: "/nutritional-counseling" },
    { label: "Developmental Screenings", href: "/developmental-screenings" },
    { label: "New Born Care", href: "/new-born-care" },
    {
      label: "Travel Medicine",
      href: "/travel-medicine",
    },
  ];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Construct the message
    const formattedMessage = encodeURIComponent(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`);
    const whatsappURL = `https://wa.me/8809076364?text=${formattedMessage}`;

    // Open the WhatsApp URL
    window.open(whatsappURL, '_blank');
  };

  return (
    <aside className="services-sidebar">
      {/* Our Services */}
      {hideTitle ? (
        <div className="services-cat-list mb-30">
          <h4 className="sw-title">Our Services</h4>
          <ul className="list-wrap">
            {services.map((item, idx) => (
              <li key={item.label} className={cn({ active: idx == 0 })}>
                <Link to={item.href}>
                  {item.label} <i className="flaticon-right-arrow"></i>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="services-widget">
          <h4 className="sw-title">Our Services</h4>
          <div className="services-cat-list">
            <ul className="list-wrap">
              {services.map((item, idx) => (
                <li key={item.label} className={cn({ active: idx == 0 })}>
                  <Link to={item.href}>
                    {item.label} <i className="flaticon-right-arrow"></i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Brochure */}

      {/* contact */}
      <div className="services-widget services-sidebar-contact">
        <h4 className="title">If You Need Any Help Contact With Us</h4>
        <a href="tel:8809076364">
          <i className="flaticon-phone-call"></i> 8809076364
        </a>
      </div>

      {/* Get A Free Quote */}
      <div className="services-widget">
        <h4 className="sw-title">Get a Free Quote</h4>
        <div className="services-widget-form">
        <form onSubmit={handleSubmit}>
        <div className="form-grp">
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-grp">
          <input
            type="email"
            placeholder="E-mail Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-grp">
          <textarea
            name="message"
            placeholder="Type Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-btn">
          Send Message
        </button>
      </form>
        </div>
      </div>
    </aside>
  );
};
