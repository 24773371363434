import React from "react";
import ESTIMATE_TIME from "../../assets/img/images/aa21.png";
import { EstimateForm } from "./EstimateForm";
import H4_SERVICES_SHAPE from "../../assets/img/images/aa1.png";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  phone: yup.string().matches(/^[0-9]+$/, 'Phone must be a number').required('Phone number is required'),
  subject: yup.string().required('Subject is required'),
  comments: yup.string().required('Comments are required'),
});

export const EstimateAreaOne = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { name, phone, email, subject, comments } = data;

    const postData = {
      name,
      phone,
      email,
      department: subject, 
      message: comments,
      toemail: ["vinayak@appaddindia.com", "skdas752@gmail.com"],
      toke: "1dytfchtgfghvyhvvhvh"
    };


    fetch('https://emailer-phi.vercel.app/hospital', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        alert('Your message has been sent successfully!');
        // Handle success
      })
      .catch((error) => {
        alert('There was an error sending your message. Please try again.');
        // Handle error
      });
  };
  return (
    <section className="estimate-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="estimate-content">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  Book Appointment
                </span>
                <h2 className="title tg-element-title">
                  Experienced Pediatrician for Trusted Childcare
                </h2>
                <p style={{ marginLeft: "0px", marginRight: "0px" }}>
                  Choose Dr. Sanjeev Kumar for his 15 years of experience and
                  MBBS qualification. As a trusted expert in pediatric care, he
                  offers exceptional treatment and procedures for various health
                  issues in Araria.
                </p>
              </div>

              <div className="estimate-tab-wrap">
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Home
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="vehicles-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#vehicles-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="vehicles-tab-pane"
                      aria-selected="false"
                    >
                      Vehicles
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="health-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#health-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="health-tab-pane"
                      aria-selected="false"
                    >
                      Health
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="life-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#life-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="life-tab-pane"
                      aria-selected="false"
                    >
                      Life
                    </button>
                  </li>
                </ul> */}

                <div style={{ margin: "0px" }} className="contact-form">
                <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" placeholder="Name *" {...register('name')} />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="email" placeholder="E-mail *" {...register('email')} />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" placeholder="Phone *" {...register('phone')} />
            {errors.phone && <p>{errors.phone.message}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" placeholder="Subject *" {...register('subject')} />
            {errors.subject && <p>{errors.subject.message}</p>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-grp">
            <textarea placeholder="Comments *" {...register('comments')}></textarea>
            {errors.comments && <p>{errors.comments.message}</p>}
          </div>
        </div>
      </div>
      <button style={{ marginBottom: "20px" }} type="submit">
        Submit Now
      </button>
    </form>
                </div>
                {/* <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="vehicles-tab-pane"
                    role="tabpanel"
                    aria-labelledby="vehicles-tab"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="health-tab-pane"
                    role="tabpanel"
                    aria-labelledby="health-tab"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="life-tab-pane"
                    role="tabpanel"
                    aria-labelledby="life-tab"
                    tabindex="0"
                  >
                    <EstimateForm />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="estimate-img text-center">
              <img style={{ minWidth: "80%" }} src={ESTIMATE_TIME} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="estimate-shape">
        <img
          src={H4_SERVICES_SHAPE}
          alt=""
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
